/* tour.css */

#tourHighlight {
    position: fixed; 
    border: greenyellow solid 2px;
    pointer-events: none;
    box-shadow: 0px 0px 6px 6px greenyellow;
    z-index: 4;
}

#tourBox {
    position: fixed;
    display: flex;
    flex-direction: row;
    border: var(--marketing-green) solid 1px; 
    border-radius: 20px;
    padding: 20px;
    padding-bottom: 5px;
    background-color: mintcream; 
    color: var(--marketing-blue);
    z-index: 3;
    max-width: 33%;
    font-family: var(--font-family);
    font-size: var(--base-font-size);
}

@media (max-width: 1024px) {
    #tourBox {
        max-width: 300px;
    }
}

.tourControls {
    background-color: mintcream;
    color: var(--marketing-blue);
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 1em 1.5em 0em 1.5em;
}

.tourControls input[type=button] {
    border: unset;
    background-color: unset;
    padding: unset;
    color: blue;
    cursor: pointer;
}

.tourContent {
    display: flex;
    flex-direction: column;
}

.tourIndicatorLeft, .tourIndicatorRight {
    align-self: center;
    display: none;
}

.tourIndicatorLeft {
    margin-left: -.5em;
    margin-right: .5em;
}

.tourIndicatorRight {
    margin-left: 0.5em;
    margin-right: -0.5em;
}

.welcomeLauncherGroup {
    display: flex;
    flex-direction: column;
}

.welcomeLauncher {
    text-align: center;
    cursor: pointer;
    margin: 4px;
    border: 1px solid var(--marketing-green);
    background-color: #f3f4f4;
    border-radius: var(--border-radius);
    padding: 2%;
    color: var(--marketing-blue);
}

.welcomeLauncher:hover {
    border-color: var(--marketing-blue);
}

.welcomeGreeting {
    font-size: larger;
    font-weight: bold;
    text-align: center;
    color: black;
}

.welcomeText {
    margin: 1em 0;
    text-align: center;
    color: black;
}