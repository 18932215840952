/* CSS for InfoDisplay.jsx, including EnergyDisplay.jsx
 *
 * Variables referenced from style.css:
 *   --mol-button-size
 *   --mol-button-margin
 *   --info-display-width
 *   --mol-button-group-width
 */
:root {
    --info-display-width: 35%;
}

/* This covers the whole canvas, but allow mouse events to pass through */
#info_display {
    position: absolute;
    display: flex;
    flex-direction: column;
    left: 0px;
    top: 0px;
    bottom: 0px;
    width: calc(100vw - 4px - var(--mol-button-group-width)); /* Stop at the style buttons */
    margin: 4px;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: calc(1px + 2vmin);
    pointer-events: none;
}

div.compound-list {
    --icon-opacity-off: 0.35;
    --icon-opacity-off-hover: 0.7;
    --icon-opacity-off-active: 0.6;
    --icon-opacity-on: 1;
    --icon-opacity-on-hover: 0.9;
    --icon-opacity-on-active: 1;
}

/* #info_display covers the whole canvas, but allows mouse events to pass through.
 * Direct children need to capture mouse events, and restrict width so mouse events
 * aren't blocked outside the children.
 */
#info_display > div {
    pointer-events: auto;
    width: fit-content;
    width: -moz-fit-content;
    width: -webkit-fit-content;
}

.systemSelector {
    flex-grow: 1;
    margin-top: calc(1em * 0.8); /* same as infoLabel */
    overflow: auto;
    max-width: 37%;
}

.systemSelector > .page {
    flex-grow: 1;
}

.systemSelector > .page > .compound-list {
    border: 0;
}

.inspector {
    max-width: 37%;
    flex-shrink: 0;
}

#info_display .generalButton {
    font-size: inherit;
    background-color: transparent;
    color: inherit;
    border: none;
    margin: 0;
    text-align: inherit;
    outline: none;
}

#info_display .generalButton:hover {
  color: var(--hover-color);
}
.proteinOpener a,
.proteinOpener a:visited {
    color: inherit;
}

.proteinInfo a:hover {
    color: var(--hover-color);
    cursor: pointer;
}

.infoItem {
    max-width: calc(var(--info-display-width) - (1em * 8.5));
}

.infoLabel {
    display: inline-block;
    margin-left: 0.5vw;
    margin-right: calc(1em * 0.5);
    margin-top: calc(1em * 0.8);
    width: calc(1em * 8.5);
}

.infoLabelIcon {
    display: inline-block;
    width: calc(1em * 1.5);
    text-decoration: none;
}

.infoHoverable:hover {
    color: var(--hover-color);
    cursor: pointer;
}

.infoUnderline {
    text-decoration: underline;
    margin-top: 10px;
}

/* CSS for EnergyDisplay.jsx */

/* The #energy_table directives are to get the separator border-bottom to be connected */
#energy_table {
    border-spacing: 0 2px;
    /* white-space: nowrap; */
}

#energy_table td,
#energy_table th {
    padding-right: 2px;
}

#request_energies_button {
    margin-top: 10px;
    padding-top: 2px;
    font-weight: bold;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: calc(1px + 2vh);
}

/* Toggle column */
.info-display-table th:nth-of-type(1) {
    width: calc(1.5em - 0.5vw);
    text-align: left;
    padding-left: 0.1em;
}

/* Label column */
.info-display-table th:nth-of-type(2) {
    text-align: left;
    padding-left: 0px;
}

/* Value columns and compound label headers*/
.info-display-table td, 
.info-display-table th /* Second th in summary row */ {
    padding-left: 10px;
    text-align: right;
}

.info-display-table tr:first-of-type:hover {
    color: inherit !important;
}

.info-display-table th {
    font-weight: normal;
    /* white-space: nowrap; */
}

.padded_inspector_page {
    padding: 8px;
}

.dialogContent {
    margin: 0px 10px 10px 10px;
    flex: 1 1 auto;
    overflow: hidden;
    display: flex;
    flex-direction: column;
}

.dialogContent > :first-child {
    flex: 1 1 auto;
}

#saveStatePanel {
    padding: 20px;
    background: white;
    border: darkgrey solid 0.5px;
    font-size: unset;
    overflow: auto;
}

#saveStatePanel div {
    margin-bottom: 10px;
}

#saveStatePanel button {
    background: none;
    border: none;
    text-align: left;
    cursor: pointer;
}

#downloadSessionLink,
#saveSessionLink {
    text-decoration: none;
    color: black;
    font-size: calc(12px + 1vh);
}

.downloadSessionButtonContainer, .downloadSessionButtonContainer_saved {
    margin: 20px;
    padding: 10px;
    list-style-type: none;
    border: lightgrey solid 0.5px;
    border-radius: 10px;
}

.downloadSessionButtonContainer:hover {
    background: lightgrey;
}

.downloadSessionLinkLooking {
    text-decoration: underline;
    color: blue;
}

.downloadSessionLinkLooking span {
    margin-left: 5px;
    font-size: calc(14px + 1vh);
}

.disabledSaveSessionOption {
    color: darkgrey;
    font-style: italic;
}

.disabledSaveSessionOption:hover {
    background: unset !important;
}

.fragment_pane {
    overflow: auto;
}

.fragment_pane,
.fragment_pane input,
.fragment_pane button,
.fragment_pane select {
    font-size: calc(12px + 1vh);
}

.fragment_pane_notice {
    font-weight: bold;
    margin-bottom: 20px;
}

.fragment_pane_option {
    margin: 20px;
    padding: 10px;
    list-style-type: none;
    border: lightgrey solid 0.5px;
    border-radius: 10px;
    background: white;
}
.fragment_pane_option_title {
    margin-bottom: 10px;
}

.fragment_pane form {
    margin: 1vh 0;
}

.fragment_pane button[type="submit"] {
    padding: 0.3vh;
    margin: 0.3vh 0;
}

.fragment_pane_cart_explanation {
    font-style: italic;
}

.simulation_components {
    margin: 10px 10px 10px 20px;
    border: grey solid 0.5px;
    padding: 10px;
    width: fit-content;
}

.simulation_components label {
    margin-left: 30px;
}

.fragment_pane-help {
    margin-left: 10px;
    font-style: italic;
    color: darkslategrey;
}

.fragment_pane-help:hover::after,
.fragment_pane-help:focus::after {
    content: attr(data-text);
    color: darkslateblue;
    margin-left: 10px;
}

.text_input_with_help label {
    width: 220px;
    display: inline-block;
}

.text_input_with_help input {
    min-width: 20%;
    max-width: 85%;
}

.structure-warning::after {
    color: firebrick;
    content: attr(data-warning);
    display: block;
}

.simulation_ready {
    font-style: italic;
}

.server-info {
    position: relative;
    right: 0;
    margin: var(--mol-button-group-border);
    top: var(--mol-button-group-border);
    height: fit-content;
}

.preview-mode-notice {
    background-color: mintcream;
    padding: 0.3em;
    border: solid var(--marketing-green);
    border-radius: 8px;
}

.preview-mode-title {
    color: var(--marketing-green);
    font-style: italic;
    text-align: center;
}

.preview-mode-desc {
    color: black;
}

.starterCompounds {
    padding: 0.5em;
    border: solid;
}

#info_display .starterAdd,
#info_display .starterClose {
    display: inline-block;
    margin: 0.5em;
}

.starterCompounds span {
    padding-left: 0.5em;
}

.compound-tree {
    flex: 1 1 auto;
}

.compoundHeritage img {
    max-width: 6em;
    max-height: 6em;
}

.compoundHeritage ol {
    margin-bottom: unset !important;
}

.compoundHeritage li {
    margin-left: 1em;
    text-align: left;
    font-size: smaller;
}

.nothing-to-show-tree {
    flex: 1 0 auto;
    /* copied from compound-tree */
    border: 1px solid #ccc;
    border-radius: 4px;
}

.nothing-to-show-tree-message {
    padding: 1.5em;
    font-style: italic;
    text-align: center;
}

.selectorHeader {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.selectorHeader label {
    margin-bottom: unset !important;
}

.selectorHeader .clearabletext input[type="text"] {
    width: 100%;
}

.viewSortMenu {
    float: right;
    flex: 1 0 auto;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.viewSortMenu .toggler::before {
    content: "View / Sort";
    padding-right: 0.5em;
}

.viewSortMenu:hover .toggler {
    opacity: var(--icon-opacity-on-hover, 0.7);
}

/* Reduce height of Selector menu items, overriding ActionMenu.css */
div.action-menu-container > div.dropdown-menu > button.dropdown-item {
    min-height: unset !important;
}

/* Used for styling selector menu */
body:not([data-theme]),    /* assume black if theme is missing */
[data-theme='black'] {
    --fg-color: #ffffff;
    --bg-color: #000000;
    --bg-color-popup: #333333;
}
[data-theme="white"] {
    --fg-color: #657b96;
    --bg-color: #f4f4f4;
    --bg-color-popup: #e5e5e5;
}
[data-theme="green"] {
    --fg-color: #f4f4f4;
    --bg-color: #006428;
    --bg-color-popup: #1e4c30;
}
[data-theme="cyan"] {
    --fg-color: #f4f4f4;
    --bg-color: #2aa198;
    --bg-color-popup: #3f7f7a;
}
[data-theme="tan"] {
    --fg-color: #657b96;
    --bg-color: #eee8d5;
    --bg-color-popup: #d8d6cd;
}

.px-component .list-column.is-active {
    width: 1em;
}
.px-component .list-column:not(.name) {
    flex: 0 0 auto;
}

.px-component .tooltip {
    font-family: var(--font-family) !important;
    font-size: var(--base-font-size) !important;
}

.px-component .tooltip.show {
    opacity: 1 !important;
}

.px-component .tooltip > .tooltip-inner {
    color: var(--fg-color);
    background-color: var(--bg-color-popup);
}
.px-component .tooltip[x-placement^="top"] > .arrow::before {
    border-top-color: var(--bg-color-popup) !important;
}
.px-component .tooltip[x-placement^="bottom"] > .arrow::before {
    border-bottom-color: var(--bg-color-popup) !important;
}

.px-component .modal-content,
.px-component .modal-content input[type="text"] {
    color: var(--fg-color) !important;
    background-color: var(--bg-color-popup) !important;
}

.px-component .dropdown-menu {
    background-color: var(--bg-color-popup) !important;
}

.px-component .dropdown-menu > .dropdown-item {
    color: var(--fg-color) !important;
}

/* Override styling for the selector menu icon */
#info_display .px-component .btn {
    font-size: calc(1px + 2vmin);
}

/* Change tooltipster colors to agree with Selector tooltips */
.tooltipster-box {
    background-color: var(--bg-color-popup) !important;
    border-color: var(--bg-color-popup) !important;
}

.tooltipster-content {
    color: var(--fg-color) !important;
    font-family: var(--font-family) !important;
    font-size: 0.9rem;
}

.tooltipster-right .tooltipster-arrow-background,
.tooltipster-right .tooltipster-arrow-border {
    border-right-color: var(--bg-color-popup) !important;
}

.tooltipster-left .tooltipster-arrow-background,
.tooltipster-left .tooltipster-arrow-border {
    border-left-color: var(--bg-color-popup) !important;
}

.tooltipster-top .tooltipster-arrow-background,
.tooltipster-top .tooltipster-arrow-border {
    border-top-color: var(--bg-color-popup) !important;
}

.tooltipster-bottom .tooltipster-arrow-background,
.tooltipster-bottom .tooltipster-arrow-border {
    border-bottom-color: var(--bg-color-popup) !important;
}
