#sketcher_wrapper {
    display: flex;
    flex-direction: column;
}

#sketcher_content {
    flex: 1;
    background-color: white;
    margin-top: 10px;
    margin-bottom: 8px;
}