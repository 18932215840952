
/* Styles for ClearableTextInput */
.clearabletext label {
    display: flex;
    align-items: center;
    position: relative;
}

.clearabletext-desc {
    margin-right: .2em;
}

.clearabletext-input {
    padding-left: .2em;
}

.clearabletext-clearbtn {
    position: absolute;
    right: .2em;
    color: darkgrey;
}

.clearabletext-clearbtn:hover {
    color: lightgrey;
}

/* Slider */
.slider {
    display: flex;
    align-items: center;
    padding: 0 1em;
}

/* Tree EnergySlider */

.treeEnergyFilter {
    padding: .5em;
}

.treeEnergyFilterSlider {
    display: flex;
}

.treeEnergyFilterSlider .slider {
    flex: 1 1 auto;
    display: flex;
}

.treeEnergyFilterSlider input {
    margin: 0 1em;
    margin-top: .3em;
}

.filterManageBtn {
    float: right;
    cursor: pointer;
}

.filterManageBtn:hover {
    opacity: var(--icon-opacity-on-hover, 0.7);
}