/* CSS for CollapsibleTable.jsx */

/* When table is collapsed:
 * 1) Hide child rows
 * 2) Stop listening for pointer events on the whole table
 * 3) But exempt the header row from from both above
 * 4) Adjust bottom margin to cover dummy row
 */ 
.collapsible-table-dummy-row {
    visibility: hidden;
    pointer-events: none;
}

.collapsible-table-collapsed-with-dummy {
    margin-bottom: -1.5em;
}

.collapsible-table tr:first-of-type:hover {
    color: lightblue;
}

.collapsible-table .separator th, 
.collapsible-table .separator td {
    border-bottom: 1px solid;
    padding-bottom: .25em;
}

.collapsible-table .separator + tr th,
.collapsible-table .separator + tr td {
    padding-top: .25em;
}