/******** Topbar Styling ********/
:root {
    --marketing-green: #89bd45;
    --marketing-blue: #0e8bd2;
    --marketing-grey: #5d5d5d;
    --topbar-bg-color: #dcf0e6;
    --topbar-height: 9vh;
    --topbar-logo-size: calc(var(--topbar-height)*2/3);
    --topbar-button-size: var(--topbar-logo-size);
    --topbar-button-color: black;
    --topbar-spacing: 1vw;
    --topbar-left-margin: var(--topbar-spacing);
    --topbar-right-margin: var(--topbar-left-margin);
    --topbar-title-fonts: 'Open Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif;
    --topbar-title-size: 4vh;
    --topbar-title-color: #006428;
}

#topbar {
    height: var(--topbar-height);
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: space-between;
    background-color: var(--topbar-bg-color);
    border-bottom: 1px solid var(--topbar-title-color);
}

#topbar a {
    display: block;
    text-decoration: none;
}

#topbar button:hover, a:hover {
    cursor: pointer;
}

#topbar-left-side {
    order: 10;
    height: 100%;
    margin-left: var(--topbar-left-margin);
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    align-items: center;
}

#topbar-right-side {
    order: 20;
    height: 100%;
    margin-right: var(--topbar-right-margin);
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-end;
    align-items: center;
    flex: 1;
}

#topbar-logo-container {
    order: 10;
    display: block;
    position: relative;
    height: var(--topbar-logo-size);
    margin-right: var(--topbar-spacing);
    text-align: center;
}

#topbar-logo-container a,
#topbar-logo-container span,
#topbar-logo-container img,
#topbar-logo-container button {
    height: 100%;
    margin: 0;
    padding: 0;
    border: none;
    text-decoration: none;
    background: none;
}

#topbar-logo-container button:focus, 
#topbar-logo-container a:focus {
    border: none;
    outline: none;
    text-decoration: none;
}

#topbar-logo-container button:hover {
    cursor: pointer;
}

#topbar-title-container {
    order: 20;
    display: flex;
    align-items: center;
    margin-right: var(--topbar-spacing);
    padding: 0;
    border: none;
    text-align: center;
    font-weight: 600;
    font-size: var(--topbar-title-size);
    font-family: var(--topbar-title-fonts);
    background: none;
}

#topbar-title-container a {
    color: var(--topbar-title-color);
}

@media only screen and (max-width: 500px) {
    #topbar-title-container {
        display: none;
    }
}

/* Buttons for main menu, help */
.topbar-button {
    position: relative;
    margin-left: var(--topbar-spacing);
    width:  var(--topbar-button-size);
    height: var(--topbar-button-size);
    border: none;
    border-radius: 10px;
    text-align: center;
    color: mintcream;
    font-family: sans-serif;
    background-color: var(--topbar-button-color);
    transition: left 0.5s linear;
}

.topbar-button i {
    font-size: 3.7vh;
}

.topbar-button-with-label i {
    margin: 0.4vh;
    font-size: 2.9vh;
}

.topbar-button:hover {
    cursor: pointer;
    color: var(--marketing-green);
}

.topbar-button:focus {
    border: none;
    outline: none;
}

#topbar-menu-button.open {
    color: var(--marketing-green);
}

.topbar-button-label {
    font-size: 1.2vh;
    text-transform: uppercase;
}
