.TestCase {
    border: solid 1px LightGrey;
    margin: 1px;
}

.TestCase button:nth-child(2) {
    float: right;
}

.TestCase > div {
    display: inline-block;
    width: 100%;
}
.TestCase .selected {
    font-weight: bold;
}

.TestCase:hover {
    border-color: cornflowerblue;
}

.test-console-title {
    font-weight: bold;
}

.testcase-name {
    font-weight: bold;
}

.testconsole-code {
    font-family: 'Courier New', Courier, monospace;
    width: 100%;
}

.testconsole-output {
    font-family: 'Courier New', Courier, monospace;
}

.testconsole-section {
    margin: 10px;
}

.testconsole-section-heading {
    font-weight: bold;
}

.testconsole-section li {
    margin: 5px;
    list-style-type: none;
}